<template>
    <div class="offer-box">
        <div class="offer-title">Received</div>
        <div class="offer-table">
            <div class="offer-table-th">
                <a-row>
                    <a-col :span="1" class="nft-items-checkbox"></a-col>
                    <a-col :span="6">NFT</a-col>
                    <a-col :span="3">Price</a-col>
                    <a-col :span="4">Make Offer</a-col>
                    <a-col :span="4">Offerer</a-col>
                    <a-col :span="6">Action</a-col>
                </a-row>
            </div>
            <div
                class="offer-table-scroll"
                v-infinite-scroll="handleInfiniteOnLoad"
                :infinite-scroll-disabled="received.busy"
                :infinite-scroll-distance="10"
                :style="{ height: received.scrollHeight }"
            >
                <a-list :data-source="received.list">
                    <a-list-item slot="renderItem" slot-scope="item">
                        <a-row :class="{ 'clear-list-row': now > item.end_time }">
                            <a-col :xs="2" :sm="2" :md="1" :lg="1" class="nft-items-checkbox">
                                <span class="nft-check"></span>
                            </a-col>
                            <a-col :xs="20" :sm="20" :md="6" :lg="6">
                                <div class="nft-info cursor" @click="linkDetail(item, 'received')">
                                    <div class="nft-img">
                                        <img :src="item.token_icon" />
                                    </div>
                                    <div class="nft-name">
                                        <h3>{{ item.token_name }}</h3>
                                        <div>
                                            <img :src="item.collect_icon" />
                                            {{ item.collect_name }}
                                        </div>
                                    </div>
                                </div>
                            </a-col>
                            <a-col :xs="{ span: 10, offset: 2 }" :sm="{ span: 10, offset: 2 }" :md="{ span: 3, offset: 0 }" :lg="{ span: 3, offset: 0 }">
                                <div class="nft-items-tit">Price</div>
                                <template v-if="item.reference">
                                    <div class="nft-price">
                                        <img class="nft-price-img" :src="item.reference.pay_token_icon" alt="" />
                                        <p>{{ item.reference.price | decimals }}</p>
                                    </div>
                                    <div class="nft-day" :style="{ opacity: [1, 2, 4].includes(item.reference.type) ? 1 : 0 }">
                                        <template v-if="item.reference.type == 2"> {{ item.reference.transfer_amount }} Share </template>
                                        <template v-else>
                                            {{ item.reference.rental_amount }} {{ item.reference.rental_amount ? 'Share' : '' }} {{ timeToString(item.reference.rental_period) }}
                                        </template>
                                    </div>
                                    <span class="nft-tag">
                                        {{ item.reference.type == 1 || item.reference.type == 4 ? 'Rental' : 'Trading' }}
                                    </span>
                                </template>
                                <template v-else>
                                    <div>---</div>
                                </template>
                            </a-col>
                            <a-col :xs="12" :sm="12" :md="4" :lg="4">
                                <div class="nft-items-tit">Make offer</div>
                                <div class="nft-price">
                                    <img class="nft-price-img" :src="item.pay_token_icon" alt="" />
                                    <p>{{ item.price | decimals }}</p>
                                </div>
                                <div class="nft-day" :style="{ opacity: [1, 2, 4].includes(item.type) ? 1 : 0 }">
                                    <template v-if="item.type == 2"> {{ item.transfer_amount }} Share </template>
                                    <template v-else> {{ item.rental_amount }} {{ item.rental_amount ? 'Share' : '' }} {{ timeToString(item.rental_period) }} </template>
                                </div>
                                <div class="nft-end" v-if="endTime(item.end_time) != 0">Ends in {{ endTime(item.end_time) }} hours</div>
                            </a-col>
                            <a-col :xs="{ span: 22, offset: 2 }" :sm="{ span: 22, offset: 2 }" :md="{ span: 4, offset: 0 }" :lg="{ span: 4, offset: 0 }">
                                <div class="nft-items-tit nft-items-offer">Offerer</div>
                                <p class="nft-offerer cursor" @click="linkAccount(item.offeror)">
                                    {{ item.offeror | showAddress }}
                                </p>
                            </a-col>
                            <a-col class="nft-action" :xs="{ span: 22, offset: 2 }" :sm="{ span: 22, offset: 2 }" :md="{ span: 6, offset: 0 }" :lg="{ span: 6, offset: 0 }">
                                <div class="nft-button">
                                    <a-button type="primary" :loading="item.reLoading" @click="ignore(item)"> Ignore </a-button>
                                    <template v-if="now <= parseInt(item.end_time)">
                                        <a-button type="primary" :loading="item.btnLoading" @click="accept(item)"> Accept </a-button>
                                    </template>
                                </div>
                            </a-col>
                        </a-row>
                    </a-list-item>
                    <div v-if="received.loading && !received.busy" class="loading-container">
                        <a-spin />
                    </div>
                </a-list>
            </div>
        </div>
        <div class="offer-title">Made</div>
        <div class="offer-table">
            <div class="offer-table-th">
                <a-row>
                    <a-col :span="1" class="nft-items-checkbox"></a-col>
                    <a-col :span="6">NFT</a-col>
                    <a-col :span="3">Price</a-col>
                    <a-col :span="4">Owner</a-col>
                    <a-col :span="4">Make Offer</a-col>
                    <a-col :span="6">Action</a-col>
                </a-row>
            </div>
            <div class="offer-table-scroll" v-infinite-scroll="handleInfiniteOnLoad1" :infinite-scroll-disabled="issued.busy" :infinite-scroll-distance="10" :style="{ height: issued.scrollHeight }">
                <a-list :data-source="issued.list">
                    <a-list-item slot="renderItem" slot-scope="item">
                        <a-row :class="{ 'clear-list-row': now > item.end_time }">
                            <a-col :xs="2" :sm="2" :md="1" :lg="1" class="nft-items-checkbox">
                                <span class="nft-check"></span>
                            </a-col>
                            <a-col :xs="20" :sm="20" :md="6" :lg="6">
                                <div class="nft-info cursor" @click="linkDetail(item)">
                                    <div class="nft-img">
                                        <img :src="item.token_icon" />
                                    </div>
                                    <div class="nft-name">
                                        <h3>{{ item.token_name }}</h3>
                                        <div>
                                            <img :src="item.collect_icon" />
                                            {{ item.collect_name }}
                                        </div>
                                    </div>
                                </div>
                            </a-col>
                            <a-col :xs="{ span: 10, offset: 2 }" :sm="{ span: 10, offset: 2 }" :md="{ span: 3, offset: 0 }" :lg="{ span: 3, offset: 0 }">
                                <div class="nft-items-tit">Price</div>
                                <template v-if="item.reference">
                                    <div class="nft-price">
                                        <img class="nft-price-img" :src="item.reference.pay_token_icon" alt="" />
                                        <p>{{ item.reference.price | decimals }}</p>
                                    </div>
                                    <div class="nft-day" :style="{ opacity: [1, 2, 4].includes(item.reference.type) ? 1 : 0 }">
                                        <template v-if="item.reference.type == 2"> {{ item.reference.transfer_amount }} Share </template>
                                        <template v-else>
                                            {{ item.reference.rental_amount }} {{ item.reference.rental_amount ? 'Share' : '' }} {{ timeToString(item.reference.rental_period) }}
                                        </template>
                                    </div>
                                    <span class="nft-tag">
                                        {{ item.reference.type == 1 || item.reference.type == 4 ? 'Rental' : 'Trading' }}
                                    </span>
                                </template>
                                <template v-else>
                                    <div>---</div>
                                </template>
                            </a-col>
                            <template v-if="isMobile">
                                <a-col :xs="12" :sm="12" :md="4" :lg="4">
                                    <div class="nft-items-tit">Make offer</div>
                                    <div class="nft-price">
                                        <img class="nft-price-img" :src="item.pay_token_icon" alt="" />
                                        <p>{{ item.price | decimals }}</p>
                                    </div>
                                    <div class="nft-day" :style="{ opacity: [1, 2, 4].includes(item.type) ? 1 : 0 }">
                                        <template v-if="item.type == 2"> {{ item.transfer_amount }} Share </template>
                                        <template v-else> {{ item.rental_amount }} {{ item.rental_amount ? 'Share' : '' }} {{ timeToString(item.rental_period) }} </template>
                                    </div>
                                    <div class="nft-end" v-if="endTime(item.end_time) != 0">Ends in {{ endTime(item.end_time) }} hours</div>
                                </a-col>
                                <a-col :xs="{ span: 22, offset: 2 }" :sm="{ span: 22, offset: 2 }" :md="{ span: 4, offset: 0 }" :lg="{ span: 4, offset: 0 }">
                                    <div class="nft-items-tit nft-items-offer">Owner</div>
                                    <p class="nft-offerer cursor" @click="linkAccount(item.owner)">
                                        {{ item.owner | showAddress }}
                                    </p>
                                </a-col>
                            </template>
                            <template v-else>
                                <a-col :xs="{ span: 22, offset: 2 }" :sm="{ span: 22, offset: 2 }" :md="{ span: 4, offset: 0 }" :lg="{ span: 4, offset: 0 }">
                                    <div class="nft-items-tit nft-items-offer">Owner</div>
                                    <p class="nft-offerer cursor" @click="linkAccount(item.owner)">
                                        {{ item.owner | showAddress }}
                                    </p>
                                </a-col>
                                <a-col :xs="12" :sm="12" :md="4" :lg="4">
                                    <div class="nft-items-tit">Make offer</div>
                                    <div class="nft-price">
                                        <img class="nft-price-img" :src="item.pay_token_icon" alt="" />
                                        <p>{{ item.price | decimals }}</p>
                                    </div>
                                    <div class="nft-day" :style="{ opacity: [1, 2, 4].includes(item.type) ? 1 : 0 }">
                                        <template v-if="item.type == 2"> {{ item.transfer_amount }} Share </template>
                                        <template v-else> {{ item.rental_amount }} {{ item.rental_amount ? 'Share' : '' }} {{ timeToString(item.rental_period) }} </template>
                                    </div>
                                    <div class="nft-end" v-if="endTime(item.end_time) != 0">Ends in {{ endTime(item.end_time) }} hours</div>
                                </a-col>
                            </template>
                            <a-col class="nft-action" :xs="{ span: 22, offset: 2 }" :sm="{ span: 22, offset: 2 }" :md="{ span: 6, offset: 0 }" :lg="{ span: 6, offset: 0 }">
                                <div class="nft-button">
                                    <template v-if="now > item.end_time">
                                        <a-button type="primary" :loading="item.btnLoading" @click="cancel(item)"> Withdraw </a-button>
                                    </template>
                                    <template v-else>
                                        <a-button type="primary" :loading="item.btnLoading" @click="cancel(item)"> Cancel </a-button>
                                    </template>
                                </div>
                            </a-col>
                        </a-row>
                    </a-list-item>
                    <div v-if="issued.loading && !issued.busy" class="loading-container">
                        <a-spin />
                    </div>
                </a-list>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import infiniteScroll from 'vue-infinite-scroll';
import { userIssued, userReceived, userIgnore } from '@/api/index';
import nftSdk from '@/sdk';
import { confirmTransaction } from '@/utils/trans';
export default {
    name: 'offerPrice',
    directives: { infiniteScroll },
    data() {
        return {
            received: {
                list: [],
                pagination: {
                    current: 1,
                    total: 0,
                    pageSize: 10,
                    size: 'small',
                },
                loading: false,
                busy: false,
                scrollHeight: 'auto',
            },
            issued: {
                list: [],
                pagination: {
                    current: 1,
                    total: 0,
                    pageSize: 10,
                    size: 'small',
                },
                loading: false,
                busy: false,
                scrollHeight: 'auto',
            },
            now: parseInt(new Date().getTime() / 1000),
        };
    },
    computed: {
        ...mapState({
            account: (state) => state.account,
            isMobile: (state) => state.isMobile,
        }),
    },
    watch: {
        account(val) {
            if (val) {
                this.getReceived();
                this.getIssued();
            } else {
                this.$router.push('/market');
            }
        },
    },
    mounted() {
        this.getReceived();
        this.getIssued();
    },
    methods: {
        endTime(time) {
            let now = parseInt(new Date().getTime() / 1000);
            let _differTime = time - now;
            if (_differTime <= 0 || _differTime > 86400) {
                return 0;
            } else {
                return Math.floor(_differTime / 3600);
            }
        },
        timeToString(time) {
            time = parseInt(time);
            if (time === 0) {
                return '';
            }
            if (time <= 60) {
                return time + ' seconds';
            }
            if (time <= 3600) {
                return parseInt(time / 60) + ' Minus';
            }
            if (time <= 86400) {
                return parseInt(time / 3600) + ' Hours';
            }
            if (time <= 2 * 86400) {
                return parseInt(time / 86400) + ' Day';
            }
            return parseInt(time / 86400) + ' Days';
        },
        getReceived(skip) {
            if (!this.account) {
                return;
            }
            if (!skip) {
                skip = 0;
            }
            userReceived({
                address: this.account,
                chain: 'Ethereum',
                skip,
                limit: 20,
            }).then(async ({ data, code, extra }) => {
                if (code != 0) {
                    return;
                }
                data.forEach((item) => {
                    item.reLoading = false;
                    item.btnLoading = false;
                });
                if (skip == 0) {
                    this.received.list = data;
                } else {
                    this.received.list = this.received.list.concat(data);
                }
                this.received.pagination.total = extra.total;
                this.received.loading = false;
            });
        },
        getIssued(skip) {
            if (!this.account) {
                return;
            }
            if (!skip) {
                skip = 0;
            }
            userIssued({
                address: this.account,
                chain: 'Ethereum',
                skip,
                limit: 20,
            }).then(async ({ data, code, extra }) => {
                if (code != 0) {
                    return;
                }
                data.forEach((item) => {
                    item.btnLoading = false;
                });
                if (skip == 0) {
                    this.issued.list = data;
                } else {
                    this.issued.list = this.issued.list.concat(data);
                }
                this.issued.pagination.total = extra.total;
                this.issued.loading = false;
            });
        },
        handleInfiniteOnLoad() {
            if (this.received.loading) return;
            if (this.received.list.length >= this.received.pagination.total) {
                this.received.loading = true;
                this.received.busy = true;
                return;
            }
            this.received.loading = true;
            this.getReceived(this.received.list.length);
        },
        handleInfiniteOnLoad1() {
            if (this.issued.loading) return;
            if (this.issued.list.length >= this.issued.pagination.total) {
                this.issued.loading = false;
                this.issued.busy = false;
                return;
            }
            this.issued.loading = true;
            this.getIssued(this.issued.list.length);
        },
        ignore(item) {
            if (item.reLoading) return;
            item.reLoading = true;
            userIgnore({ order_id: item.oid })
                .then(({ code }) => {
                    if (code != 0) {
                        return;
                    }
                    this.getReceived();
                    item.reLoading = false;
                })
                .catch(() => {
                    item.reLoading = false;
                });
        },
        async accept(item) {
            if (item.btnLoading) return;
            item.btnLoading = true;
            try {
                let tx = await nftSdk.take(item.oid, item.pay_token);
                await confirmTransaction(tx.hash);
                setTimeout(() => {
                    this.getReceived();
                    item.btnLoading = false;
                    this.$notification['success']({
                        message: 'Transaction success',
                    });
                }, 4000);
            } catch (err) {
                item.btnLoading = false;
                if (err.code == 4001 || err.code == 'ACTION_REJECTED') {
                    this.$notification['error']({
                        message: 'Transcation cancelled',
                    });
                    return;
                }
                this.$notification['error']({
                    message: 'Transcation failed',
                });
            } finally {
                item.btnLoading = false;
            }
        },
        async cancel(item) {
            if (item.btnLoading) return;
            item.btnLoading = true;
            try {
                let tx = await nftSdk.cancel(item.oid);
                await confirmTransaction(tx.hash);
                setTimeout(() => {
                    this.getIssued();
                    item.btnLoading = false;
                    this.$notification['success']({
                        message: 'Transaction success',
                    });
                }, 4000);
            } catch (err) {
                item.btnLoading = false;
                if (err.code == 4001 || err.code == 'ACTION_REJECTED') {
                    this.$notification['error']({
                        message: 'Transcation cancelled',
                    });
                    return;
                }
                this.$notification['error']({
                    message: 'Transcation failed',
                });
            } finally {
                item.btnLoading = false;
            }
        },
        linkDetail(item, code) {
            let url = `/market/${item.chain_name}/${item.contract_address}/${item.token_id}`;
            if ([2, 3, 4].includes(item.type)) {
                let owner = `/${item.owner}`;
                if (code == 'received') {
                    owner = `/${this.account}`;
                }
                url = url + owner;
            }
            this.$router.push(url);
        },
        linkAccount(address) {
            this.$router.push('/account/' + address);
        },
    },
};
</script>
<style scoped lang="scss">
.offer-box {
    padding-top: 30px;
    padding-bottom: 80px;
}
.offer-title {
    width: 1200px;
    margin: 42px auto 20px;
    font-size: 16px;
    font-family: 'SFPixelate-Bold';
    font-weight: bold;
    color: #ffffff;
    line-height: 18px;
}
.offer-table {
    width: 1200px;
    margin: 0 auto;
    background: rgba(10, 66, 61, 1);
    .nft-items-checkbox {
        width: 34px;
    }
    .offer-table-th {
        width: 100%;
        padding: 0 2%;
        height: 50px;
        background-image: url('~@/assets/images/market/offer-table-bg.svg');
        background-repeat: no-repeat;
        //background-color: rgba(12, 45, 51, 1);
        display: flex;
        align-items: center;
        .ant-row {
            width: 100%;
            font-size: 12px;
            font-family: 'SFPixelate-Bold';
            font-weight: bold;
            color: #ffffff;
            line-height: 14px;
        }
    }
    .offer-table-scroll {
        .ant-list-split {
            width: 96%;
            margin: 0 auto;
            .ant-list-item {
                padding: 32px 0;
                border-bottom-color: #025839;
            }
        }
        .ant-row {
            width: 100%;
            font-family: 'SFPixelate-Bold';
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            color: #ffffff;
            display: flex;
            align-items: center;
            .nft-check {
                width: 10px;
                height: 10px;
                background: #01c67f;
                border-radius: 10px;
                border: 1px solid #025839;
                display: block;
            }
            .nft-items-tit {
                display: none;
            }
            .nft-info {
                display: flex;
                .nft-img {
                    width: 60px;
                    height: 60px;
                    background: #000000;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .nft-name {
                    margin-left: 20px;
                    h3 {
                        font-size: 14px;
                        line-height: 16px;
                        min-height: 32px;
                        color: #ffffff;
                    }
                    div {
                        margin-top: 4px;
                        font-size: 12px;
                        line-height: 14px;
                        display: flex;
                        align-items: flex-end;
                        img {
                            width: 24px;
                            height: 24px;
                            background: #d8d8d8;
                            margin-right: 4px;
                        }
                    }
                }
            }
            .nft-price {
                display: flex;
                .nft-price-img {
                    width: 16px;
                    height: 16px;
                }
                p {
                    padding-left: 4px;
                    font-size: 14px;
                    line-height: 16px;
                }
            }
            .nft-day {
                padding: 6px 0;
                height: 28px;
            }
            .nft-tag {
                padding: 0 12px;
                background: #de8900;
                color: #072a2c;
            }
            .nft-end {
                color: #de8900;
            }
            .nft-offerer {
                font-size: 14px;
                line-height: 16px;
                color: #01c67f;
            }
            .nft-button {
                display: flex;
                button {
                    width: 131px;
                    height: 30px;
                    border: none;
                    background: url('~@/assets/images/market/offer-btn-bg.svg') no-repeat;
                    box-shadow: none;
                    color: #01c67f;
                    &:nth-child(2) {
                        margin-left: 24px;
                    }
                    &.nft-button-active {
                        background: url('~@/assets/images/market/offer-btn-bg1.svg') no-repeat;
                        color: #000000;
                    }
                }
            }
        }
        .clear-list-row {
            .ant-col:not(.nft-action) {
                opacity: 0.6;
            }
            .nft-check {
                background: #072a2c;
            }
        }
    }
}
@media screen and (max-width: 750px) {
    .offer-box {
        padding: 0.2rem 0.32rem 1.12rem;
    }
    .offer-title {
        width: 100%;
        margin: 0.4rem auto;
        font-size: 0.32rem;
        line-height: 0.34rem;
    }
    .offer-table {
        width: 100%;
        background: none;
        .nft-items-checkbox {
            width: 8.33333333%;
        }
        .offer-table-th {
            display: none;
        }
        .offer-table-scroll {
            :deep(.ant-checkbox-wrapper) {
                .ant-checkbox {
                    .ant-checkbox-inner {
                        width: 0.2rem;
                        height: 0.2rem;
                        border-radius: 0.2rem;
                    }
                }
            }
            .ant-list-split {
                width: 100%;
                .ant-list-item {
                    width: 6.86rem;
                    height: 5.96rem;
                    padding: 0.4rem 0.32rem;
                    margin-bottom: 0.24rem;
                    border-bottom: none;
                    background: url('~@/assets/images/market/offer-item-bg.svg') no-repeat;
                    background-size: contain;
                    align-items: flex-start;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .ant-row {
                font-size: 0.24rem;
                line-height: 0.28rem;
                flex-wrap: wrap;
                align-items: flex-start;
                .nft-check {
                    margin-top: 0.5rem;
                }
                .nft-items-tit {
                    display: block;
                    padding: 0.4rem 0 0.16rem;
                    font-size: 0.24rem;
                    line-height: 0.28rem;
                    color: #8babab;
                    &.nft-items-offer {
                        padding-top: 0.24rem;
                    }
                }
                .nft-info {
                    .nft-img {
                        width: 1.2rem;
                        height: 1.2rem;
                    }
                    .nft-name {
                        margin-left: 0.24rem;
                        h3 {
                            font-size: 0.28rem;
                            line-height: 0.32rem;
                            min-height: 0.64rem;
                        }
                        div {
                            margin-top: 0.1rem;
                            font-size: 0.24rem;
                            line-height: 0.28rem;
                            span {
                                width: 0.48rem;
                                height: 0.48rem;
                                margin-right: 0.08rem;
                            }
                        }
                    }
                }
                .nft-price {
                    .nft-price-img {
                        width: 0.32rem;
                        height: 0.32rem;
                    }
                    p {
                        padding-left: 0.08rem;
                        font-size: 0.28rem;
                        line-height: 0.32rem;
                    }
                }
                .nft-day {
                    padding: 0.12rem 0;
                    height: 0.56rem;
                }
                .nft-tag {
                    padding: 0 0.24rem;
                }
                .nft-offerer {
                    font-size: 0.28rem;
                    line-height: 0.32rem;
                }
                .nft-button {
                    padding-top: 0.32rem;
                    button {
                        width: 2.62rem;
                        height: 0.6rem;
                        background-size: contain;
                        &:nth-child(2) {
                            margin-left: 0.4rem;
                            background-size: contain;
                        }
                    }
                }
            }
        }
    }
}
</style>
